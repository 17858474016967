export interface AppResources {
  addressForm: {
    address1: string
    address2: string
    locality: string
    state: string
    postCode: string
    country: string
  }
  footer: {
    copyright: string
  }
  gauges: {
    downloadSpeed: string
    uploadSpeed: string
  }
  stepper: {
    confirmAddress: string
    confirmAddressDescription: string
    runSpeedTest: string
    runSpeedTestDescription: string
    register: string
    registerDescription: string
    thankYou: string
    thankYouDescription: string
    continue: string
    back: string
  }
  notification: {
    testInProgress: string
    testCompleted: string
  }
  pulseButton: {
    run: string
    runAgain: string
    running: string
    login: string
  }
  drawer: {
    poweredBy: string
    yourData: string
  }
  profile: {
    welcome: string
    logout: string
  }
}

export interface IpInfo {
  ip: string
  asn: string
  hostname: string
  city: string
}

export interface AddressInfo {
  city: string
  state: string
  address1: string
  address2: string
  zip: string
  country: string
}

export type IpInfoKey = keyof IpInfo

export interface ApplicationContext {
  clientIpInfo?: IpInfo
  serverIpInfo?: IpInfo
  address: AddressInfo
  setAddress: (address: AddressInfo) => void
  appResources: AppResources
}

export interface SpeedTestResult {
  downloadSpeed: number
  uploadSpeed: number
}

export interface UserInfo extends SpeedTestResult {
  email: string
  name: string
  ipInfo: IpInfo
}

export interface SpeedTestRecord {
  users: UserInfo[]
  addressInfo: AddressInfo
  addressHash: number
}

export type SpeedTestState = 'Execute' | 'Running' | 'Completed'

export interface SpeedTestContext {
  setDownloadMbps: (value: number) => void
  setUploadMbps: (value: number) => void
  downloadMbps: number
  uploadMbps: number
  state: SpeedTestState
  setState: (state: SpeedTestState) => void
}

export const getNextState = (currentState: SpeedTestState) => {
  switch (currentState) {
    case 'Execute':
      return 'Running'
    default:
      return 'Execute'
  }
}

export const getHydratedAddressInfo = ({
  city,
  state,
  address1,
  address2,
  zip,
  country,
}: AddressInfo): AddressInfo | undefined => {
  if (city && state && address1 && zip && country) {
    return {
      city,
      state,
      address1,
      address2,
      zip,
      country,
    }
  }
}

export const hashAddress = (addressInfo: AddressInfo) => {
  const string = [
    addressInfo.address1,
    addressInfo.address2,
    addressInfo.city,
    addressInfo.country,
    addressInfo.state,
    addressInfo.zip,
  ].join('')

  let hash = 0
  for (let i = 0; i < string.length; ++i) {
    const char = string.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0
  }
  return hash
}
