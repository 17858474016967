import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Info } from '@mui/icons-material'
import {
  Alert,
  CircularProgress,
  FormControl,
  Grid,
  Snackbar,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { useAppContext } from '../../context/app-context'
import { useSpeedTestContext } from '../../context/speed-test-context'
import telemetryService from '../../services/telemetry-service'
import theme from '../../theme'
import {
  AddressInfo,
  AppResources,
  getHydratedAddressInfo,
  SpeedTestContext,
} from '../../types'
import AddressForm from '../address-form/address-form'
import GaugeGrid from '../gauge-grid'

interface ComponentProps {
  onNext: React.MouseEventHandler<HTMLButtonElement>
  onBack: React.MouseEventHandler<HTMLButtonElement>
  validAddress?: AddressInfo
  speedTestContext: SpeedTestContext
}

const steps = (appResources: AppResources) => [
  {
    label: appResources.stepper.runSpeedTest,
    description: appResources.stepper.runSpeedTestDescription,
    component: (props: ComponentProps) => {
      return (
        <>
          <GaugeGrid />
          {props.speedTestContext.state !== 'Running' && (
            <Button
              variant="contained"
              onClick={props.onNext}
              sx={{ mt: 1, mr: 1, margin: '0 auto' }}
            >
              {appResources.stepper.continue}
            </Button>
          )}
        </>
      )
    },
  },
  {
    label: appResources.stepper.confirmAddress,
    description: appResources.stepper.confirmAddressDescription,
    component: (props: ComponentProps) => {
      return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <AddressForm />
          <FormControl>
            <Button
              variant="contained"
              onClick={props.onNext}
              sx={{
                mt: 1,
                mr: 1,
              }}
            >
              {appResources.stepper.continue}
            </Button>
          </FormControl>
        </Box>
      )
    },
  },
]

const registerSteps = (appResources: AppResources) => [
  ...steps(appResources),
  {
    label: appResources.stepper.register,
    description: appResources.stepper.registerDescription,
    component: (props: ComponentProps) => {
      return <GaugeGrid />
    },
  },
]

const alreadyRegistered = (appResources: AppResources) => [
  ...steps(appResources),
  {
    label: appResources.stepper.thankYou,
    description: appResources.stepper.thankYouDescription,
    component: (_: ComponentProps) => {
      const user = useAuth0().user
      useEffect(() => {
        telemetryService
          .save(undefined, user, undefined, undefined)
          .then(() => {})
      }, [])
      return (
        <div style={{ margin: '0 auto' }}>
          <a href="https://tribuquent.com" style={{ cursor: 'pointer' }}>
            <img src="/tribuquent.png" width="160px" height="44px" />
          </a>
        </div>
      )
    },
  },
]

const ContentHolder = ({
  children,
  orientation,
}: {
  children: ReactNode
  orientation: 'vertical' | 'horizontal'
}) => {
  if (orientation === 'horizontal') {
    return <div>{children}</div>
  } else {
    return <StepContent>{children}</StepContent>
  }
}

const AppStepper = () => {
  const authContext = useAuth0()
  const appContext = useAppContext()
  const speedTestContext = useSpeedTestContext()
  const [activeStep, setActiveStep] = useState(0)

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const orientation = isSmall ? 'vertical' : 'horizontal'

  const registeredResources = useMemo(
    () => alreadyRegistered(appContext.appResources),
    [appContext.appResources]
  )

  const unregisteredResources = useMemo(
    () => registerSteps(appContext.appResources),
    [appContext.appResources]
  )

  useEffect(() => {
    if (authContext.isAuthenticated) {
      setActiveStep(2)
    }
  }, [authContext.isAuthenticated])

  if (authContext.isLoading) {
    return <CircularProgress />
  }

  const onNext = (e: React.MouseEvent<HTMLButtonElement | null>) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    e.stopPropagation()
  }

  const onBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const validAddress = getHydratedAddressInfo(appContext.address)
  const stepsToTake = authContext.isAuthenticated
    ? registeredResources
    : unregisteredResources

  const content = (
    <>
      {stepsToTake.map((step, index) => (
        <React.Fragment key={`step-${index}`}>
          <ContentHolder orientation={orientation}>
            {activeStep === index && (
              <Typography
                sx={{
                  fontSize: '18px',
                  color: 'gray',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: '1rem 0',
                }}
              >
                {step.description}
              </Typography>
            )}
            <Grid container sx={{ textAlign: 'center' }}>
              {index === activeStep && (
                <step.component
                  onNext={onNext}
                  onBack={onBack}
                  validAddress={validAddress}
                  speedTestContext={speedTestContext}
                />
              )}
              <Snackbar
                open={speedTestContext.state == 'Running'}
                autoHideDuration={10000}
              >
                <Alert
                  severity="info"
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                  }}
                  icon={<Info sx={{ fill: 'white' }} />}
                >
                  {appContext.appResources.notification.testInProgress}
                </Alert>
              </Snackbar>
              <Snackbar open={speedTestContext.state == 'Completed'}>
                <Alert severity="success" sx={{ width: '100%' }}>
                  {appContext.appResources.notification.testCompleted}
                </Alert>
              </Snackbar>
            </Grid>
          </ContentHolder>
        </React.Fragment>
      ))}
    </>
  )

  return (
    <Box sx={{ maxWidth: isSmall ? 400 : undefined }}>
      <Stepper
        activeStep={activeStep}
        orientation={orientation}
        sx={{ color: theme.palette.secondary.main }}
      >
        {stepsToTake.map((step, index) => (
          <Step
            key={`step-${index}-${step.label}`}
            onClick={() => {
              if (authContext.isAuthenticated) {
                setActiveStep(index)
              }
            }}
            sx={{
              cursor:
                authContext.isAuthenticated && index > 0
                  ? 'pointer'
                  : 'default',
            }}
          >
            <StepLabel>{step.label}</StepLabel>
            {orientation === 'vertical' && content}
          </Step>
        ))}
      </Stepper>
      {orientation === 'horizontal' && content}
    </Box>
  )
}

export default AppStepper
