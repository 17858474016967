import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../context/app-context'
import { useSpeedTestContext } from '../../context/speed-test-context'
import Gauge from './index'

const UploadGauge = () => {
  const [max, setMax] = useState(0)
  const [viewBytes, setViewBytes] = useState(0)
  const { uploadMbps } = useSpeedTestContext()
  const appContext = useAppContext()

  useEffect(() => {
    setViewBytes(Math.floor(uploadMbps))
    if (uploadMbps > max) {
      setMax(uploadMbps)
    }
  }, [uploadMbps])

  return (
    <Gauge
      value={viewBytes}
      max={max}
      min={0}
      label={appContext.appResources.gauges.uploadSpeed}
      units="Mbps"
    />
  )
}

export default UploadGauge
