import * as React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import theme from '../theme'
import IpInfoGrid from './ip-info-grid'
import PoweredByGrid from './powered-by-grid'

const anchorVariants = ['left', 'right', 'top', 'bottom'] as const

type AnchorVariant = typeof anchorVariants[number]

const IpInfoDrawer = () => {
  const [isOpen, setOpen] = React.useState(false)
  const isMedOrGreater = useMediaQuery(theme.breakpoints.up('sm'))
  const anchor: AnchorVariant = isMedOrGreater ? 'left' : 'top'

  const toggleDrawer = (event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setOpen(!isOpen)
  }

  const View = () => (
    <Box
      sx={{ width: anchor === 'top' ? 'auto' : 300, height: '100vh' }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <IpInfoGrid />
      <PoweredByGrid />
    </Box>
  )

  return (
    <IconButton
      size="large"
      edge="start"
      aria-label="menu"
      sx={{ mr: 2, color: 'white' }}
      onClick={toggleDrawer}
    >
      <MenuIcon />
      <SwipeableDrawer
        anchor={anchor}
        open={isOpen}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <View />
      </SwipeableDrawer>
    </IconButton>
  )
}

export default IpInfoDrawer
