import './PulseButton.css'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Typography } from '@mui/material'
import { useAppContext } from '../../context/app-context'
import { useSpeedTestContext } from '../../context/speed-test-context'
import ndtService from '../../services/ndt-service'
import telemetryService from '../../services/telemetry-service'
import theme from '../../theme'

const PulseButton = () => {
  const { loginWithRedirect, user, isAuthenticated } = useAuth0()
  const speedTestContext = useSpeedTestContext()
  const appContext = useAppContext()
  const resources = appContext.appResources.pulseButton
  const [text, setText] = useState<string>(resources.run)

  useEffect(() => {
    switch (speedTestContext.state) {
      case 'Execute':
        setText(resources.run)
        break
      case 'Running':
        setText(resources.running)
        break
      case 'Completed':
        if (!isAuthenticated) {
          setText(resources.login)
        } else {
          setText(resources.runAgain)
        }
        telemetryService
          .save(
            appContext.clientIpInfo,
            user,
            speedTestContext,
            appContext.address
          )
          .then(() => {})
        break
      default:
        break
    }
  }, [speedTestContext.state])

  useEffect(() => {
    if (
      text === resources.run &&
      speedTestContext.state === 'Execute' &&
      !isAuthenticated
    ) {
      speedTestContext.setState('Running')
      ndtService.executeSpeedTest(speedTestContext).then(() => {
        speedTestContext.setState('Completed')
      })
    }
  }, [])

  const onClick = async () => {
    switch (text) {
      case resources.login:
        loginWithRedirect()
        break
      case resources.run:
      case resources.runAgain:
        speedTestContext.setState('Running')
        await ndtService.executeSpeedTest(speedTestContext)
        speedTestContext.setState('Completed')
        break

      default:
        break
    }
  }

  return (
    <div className="pulse-container">
      <div className="pulse-button-bg"></div>
      <div className="pulse-button-ring" />
      <div className="pulse-button">
        <Typography
          sx={{
            fontFamily: 'Georgia, serif',
            fontSize: '20px',
            color: theme.palette.primary.main,
          }}
          onClick={onClick}
        >
          {text}
        </Typography>
      </div>
    </div>
  )
}

export default PulseButton
