import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../context/app-context'
import { useSpeedTestContext } from '../../context/speed-test-context'
import Gauge from './index'

const DownloadGauge = () => {
  const [max, setMax] = useState(0)
  const [viewBytes, setViewBytes] = useState(0)
  const { downloadMbps } = useSpeedTestContext()
  const appContext = useAppContext()

  useEffect(() => {
    setViewBytes(Math.floor(downloadMbps))
    if (downloadMbps > max) {
      setMax(downloadMbps)
    }
  }, [downloadMbps])

  return (
    <Gauge
      value={viewBytes}
      max={max}
      min={0}
      label={appContext.appResources.gauges.downloadSpeed}
      units="Mbps"
    />
  )
}

export default DownloadGauge
