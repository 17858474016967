import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useAppContext } from '../context/app-context'
import theme from '../theme'
import IpInfoView from './info-icon/ip-info-view'
import TestServerView from './info-icon/test-server-view'
import UserInfoView from './info-icon/user-info-view'

const IpInfoGrid = () => {
  const appContext = useAppContext()
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ padding: '1rem 1rem 0 1rem' }}
    >
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {appContext.appResources.drawer.yourData}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12} sx={{ textAlign: 'right' }}>
        <IpInfoView />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12} sx={{ textAlign: 'left' }}>
        <TestServerView />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12} sx={{ textAlign: 'left' }}>
        <UserInfoView />
      </Grid>
    </Grid>
  )
}

export default IpInfoGrid
