import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { styled } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IpInfoDrawer from './ip-info-drawer'
import Profile from './profile'

const appBarColor = '#2596be'

const StyledNavBar = styled('div')({
  flexGrow: 1,
})

const StyledProfile = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  width: '100%',
})

const NavBar = ({}: {}) => {
  const { isAuthenticated } = useAuth0()

  return (
    <StyledNavBar>
      <AppBar position="static" sx={{ color: appBarColor, display: 'flex' }}>
        <Toolbar>
          <IpInfoDrawer />
          <StyledProfile>{isAuthenticated && <Profile />}</StyledProfile>
        </Toolbar>
      </AppBar>
    </StyledNavBar>
  )
}

export default NavBar
