import { AppResources } from '../types'

const defaultAppResources: AppResources = {
  addressForm: {
    address1: '',
    address2: '',
    locality: '',
    state: '',
    postCode: '',
    country: '',
  },
  footer: {
    copyright: '',
  },
  gauges: {
    downloadSpeed: '',
    uploadSpeed: '',
  },
  stepper: {
    confirmAddress: '',
    confirmAddressDescription: '',
    runSpeedTest: '',
    runSpeedTestDescription: '',
    register: '',
    registerDescription: '',
    thankYou: '',
    thankYouDescription: '',
    continue: '',
    back: '',
  },
  notification: {
    testInProgress: '',
    testCompleted: '',
  },
  pulseButton: {
    run: '',
    runAgain: '',
    running: '',
    login: '',
  },
  drawer: {
    poweredBy: '',
    yourData: '',
  },
  profile: {
    welcome: '',
    logout: '',
  },
}

const getResources = async (language: string = 'en') => {
  try {
    const resources = await fetch(`/resources/${language}.json`)
    const json = await resources.json()
    return json as unknown as AppResources
  } catch (e) {
    if (language !== 'en') {
      const resources = await fetch(`/resources/en.json`)
      const json = await resources.json()
      return json as unknown as AppResources
    } else {
      return defaultAppResources
    }
  }
}

export default {
  getResources,
  defaultAppResources,
}
