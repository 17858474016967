import './address-form.css'
import React, { createRef, useEffect, useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import { useAppContext } from '../../context/app-context'
import { AddressInfo } from '../../types'
import telemetryService, { saveData } from '../../services/telemetry-service'

type AutoComplete = google.maps.places.Autocomplete
type AddressKey = keyof AddressInfo

const AddressForm = () => {
  const appContext = useAppContext()
  const streetFieldRef = createRef<HTMLInputElement>()
  const unitFieldRef = createRef<HTMLInputElement>()
  const [addressInfo, setAddressInfo] = useState<AddressInfo>(
    appContext.address
  )

  useEffect(() => {
    const info = telemetryService.resolveAddressInfo()
    console.log('INFO', info)
    if (info != null) setAddressInfo(info)
  }, [])

  useEffect(() => {
    appContext.setAddress(addressInfo)
    saveData('address-info', addressInfo)
  }, [addressInfo])

  const [_, setAutoComplete] = useState<AutoComplete | undefined>()

  const completeAddress = (complete: AutoComplete) => {
    if (complete == null) return
    // Get the place details from the autocomplete object.
    const place = complete.getPlace()
    let streetAddress = ''
    let postcode = ''
    if (place == null) return

    try {
      const components =
        place.address_components as google.maps.GeocoderAddressComponent[]

      const newAddressInfo: AddressInfo = { ...addressInfo }

      for (const component of components || []) {
        // @ts-ignore
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            streetAddress = `${component.long_name} ${streetAddress}`
            break
          }

          case 'route': {
            streetAddress += component.short_name
            break
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`
            break
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`
            break
          }

          case 'locality': {
            newAddressInfo.city = component.long_name
            break
          }

          case 'administrative_area_level_1': {
            newAddressInfo.state = component.short_name
            break
          }

          case 'country': {
            newAddressInfo.country = component.short_name
            break
          }
        }
      }

      newAddressInfo.address1 = streetAddress
      newAddressInfo.zip = postcode
      setAddressInfo(newAddressInfo)

      const unit = document.getElementById('address2') as HTMLInputElement
      const street = document.getElementById('ship-address') as HTMLInputElement

      if (unit != null) {
        unit.focus()
      }

      if (street != null) {
        street.value = streetAddress
      }
    } catch (e) {
      // ignore this error
    }
  }

  useEffect(() => {
    if (streetFieldRef.current != null) {
      const newAutocomplete = new google.maps.places.Autocomplete(
        streetFieldRef.current,
        {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components'],
          types: ['address'],
        }
      )
      newAutocomplete.addListener('place_changed', () =>
        completeAddress(newAutocomplete)
      )
      setAutoComplete(newAutocomplete)
      const street = document.getElementById('ship-address') as HTMLInputElement
      if (street != null) {
        street.value = addressInfo.address1
      }
      streetFieldRef.current.focus()
    }
  }, [])

  return (
    <form id="address-form" action="" method="get" autoComplete="none">
      <FormControl sx={{ paddingBottom: '1rem', width: '100%' }}>
        <TextField
          inputProps={{
            form: { automComplete: 'off' },
            placeholder: appContext.appResources.addressForm.address1,
          }}
          onBlur={(e) => {
            setAddressInfo((prev) => ({ ...prev, address1: e.target.value }))
          }}
          id="ship-address"
          name="ship-address"
          inputRef={streetFieldRef}
          label={appContext.appResources.addressForm.address1}
          fullWidth
          required
        />
      </FormControl>
      <FormControl sx={{ paddingBottom: '1rem', width: '100%' }}>
        <TextField
          label={appContext.appResources.addressForm.address2}
          id="address2"
          name="address2"
          autoComplete="off"
          inputRef={unitFieldRef}
          value={addressInfo.address2}
          onChange={(e) =>
            setAddressInfo((prev) => ({ ...prev, address2: e.target.value }))
          }
          fullWidth
        />
      </FormControl>
      <FormControl sx={{ paddingBottom: '1rem', width: '100%' }}>
        <TextField
          label={appContext.appResources.addressForm.locality}
          id="locality"
          name="locality"
          autoComplete="off"
          value={addressInfo.city}
          onChange={(e) =>
            setAddressInfo((prev) => ({ ...prev, city: e.target.value }))
          }
          required
          fullWidth
        />
      </FormControl>
      <FormControl sx={{ paddingBottom: '1rem', width: '100%' }}>
        <TextField
          label={appContext.appResources.addressForm.state}
          id="state"
          name="state"
          autoComplete="off"
          value={addressInfo.state}
          onChange={(e) =>
            setAddressInfo((prev) => ({ ...prev, state: e.target.value }))
          }
          required
          fullWidth
        />
      </FormControl>
      <FormControl sx={{ paddingBottom: '1rem', width: '100%' }}>
        <TextField
          label={appContext.appResources.addressForm.postCode}
          id="postcode"
          name="postcode"
          autoComplete="off"
          value={addressInfo.zip}
          onChange={(e) =>
            setAddressInfo((prev) => ({ ...prev, zip: e.target.value }))
          }
          required
          fullWidth
        />
      </FormControl>
      <FormControl sx={{ paddingBottom: '1rem', width: '100%' }}>
        <TextField
          label={appContext.appResources.addressForm.country}
          id="country"
          name="country"
          autoComplete="off"
          value={addressInfo.country}
          onChange={(e) =>
            setAddressInfo((prev) => ({ ...prev, country: e.target.value }))
          }
          required
          fullWidth
        />
      </FormControl>
    </form>
  )
}

export default AddressForm
