import React, { MouseEvent, MouseEventHandler, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { Avatar, Button, Menu, MenuItem, Typography } from '@mui/material'
import { useAppContext } from '../context/app-context'
import theme from '../theme'

const StyledWelcomeCard = styled('div')({
  width: '290px',
  textAlign: 'center',
  padding: '10px',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 20%)',
})

const StyledUserName = styled('span')({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
})

const Profile = () => {
  const appContext = useAppContext()
  const { user, logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const handleClick: MouseEventHandler = (
    event: MouseEvent<HTMLAnchorElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Avatar
        aria-controls="simple-menu"
        aria-haspopup="true"
        alt={user?.name}
        src={user?.picture}
        sx={{
          cursor: 'pointer',
          color: theme.palette.secondary.main,
          backgroundColor: 'white',
        }}
        onClick={handleClick}
      >
        {(user?.name ?? 'P').charAt(0)}
      </Avatar>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          list: {
            paddingTop: '0',
            paddingBottom: 0,
          },
        }}
      >
        <StyledWelcomeCard>
          <Typography sx={{ fontSize: '14px !important' }}>
            {appContext.appResources.profile.welcome}
            <StyledUserName>
              {user?.preferred_username || user?.name}!
            </StyledUserName>
          </Typography>
        </StyledWelcomeCard>
        <MenuItem>
          <Button
            onClick={() => {
              logout({ returnTo: window.location.origin })
            }}
            sx={{
              width: '100%',
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              textAlign: 'center',
              '&:hover,&:focus': {
                backgroundColor: '#35A6bF',
              },
              textTransform: 'none',
            }}
          >
            {appContext.appResources.profile.logout}
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}

export default Profile
