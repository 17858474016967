import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { SpeedTestContext, SpeedTestState } from '../types'

const speedTestContext = React.createContext<SpeedTestContext>({
  uploadMbps: 0,
  downloadMbps: 0,
  setUploadMbps: () => null,
  setDownloadMbps: () => null,
  state: 'Execute',
  setState: () => null,
})

const SpeedTestContextProvider = ({ children }: { children: ReactNode }) => {
  const [uploadMbps, setUploadMbps] = useState(0)
  const [downloadMbps, setDownloadMbps] = useState(0)
  const [state, setState] = useState<SpeedTestState>('Execute')

  const context: SpeedTestContext = {
    setState,
    state,
    uploadMbps,
    downloadMbps,
    setUploadMbps,
    setDownloadMbps,
  }

  return (
    <speedTestContext.Provider value={context}>
      {children}
    </speedTestContext.Provider>
  )
}

export const useSpeedTestContext = () => useContext(speedTestContext)

export default SpeedTestContextProvider
