import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import theme from '../../theme'

const StyledAttributes = styled('div')({
  textAlign: 'left',
  color: 'grey',
  marginRight: '1rem',
  marginLeft: '1rem',
  fontSize: '18px',
  fontWeight: 'bold',
})

const StyledIcon = styled('span')({
  textAlign: 'right',
  marginRight: 0,
})

interface InfoIconProps {
  attributes: (string | undefined)[]
  icon: ReactNode
  id: string
  alignItems?: 'center' | 'left' | 'right'
  justifyContent?: 'center' | 'left' | 'right'
}

const InfoIcon = ({
  attributes,
  icon,
  id,
  alignItems = 'left',
  justifyContent = 'left',
}: InfoIconProps) => {
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const attributeView = attributes
    .filter((s) => s)
    .map((attribute, index) => (
      <Typography key={`info-${id}-${index}`} sx={{ width: '100%' }}>
        {attribute}
      </Typography>
    ))

  return (
    <Grid
      container
      sx={{ width: '100%' }}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      <Grid
        item
        sx={{
          textAlign: !isLarge ? 'right' : 'left',
        }}
      >
        <StyledIcon>{icon}</StyledIcon>
      </Grid>
      <Grid item>
        <StyledAttributes>{attributeView}</StyledAttributes>
      </Grid>
    </Grid>
  )
}

export const iconStyle = {
  fill: theme.palette.primary.main,
  borderRadius: '100%',
  border: `2px solid ${theme.palette.primary.main}`,
  fontSize: '3rem',
}

export default InfoIcon
