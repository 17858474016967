import './App.css'
import React from 'react'
import { Container, ThemeProvider } from '@mui/material'
import Footer from './components/footer'
import NavBar from './components/nav-bar'
import AppStepper from './components/stepper'
import AppContextProvider from './context/app-context'
import SpeedTestContextProvider from './context/speed-test-context'
import theme from './theme'

function App() {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          <NavBar />
          <Container sx={{ paddingTop: '1rem' }}>
            <SpeedTestContextProvider>
              <AppStepper />
            </SpeedTestContextProvider>

            <Footer />
          </Container>
        </div>
      </ThemeProvider>
    </AppContextProvider>
  )
}

export default App
