// @ts-nocheck
import { SpeedTestContext } from '../types'

const config = {
  userAcceptedDataPolicy: true,
  downloadworkerfile: '/scripts/ndt7-download-worker.min.js',
  uploadworkerfile: '/scripts/ndt7-upload-worker.min.js',
}

const resolveMbps = (measurement) => {
  const mbps = measurement.Data.MeanClientMbps
  return mbps.toFixed(2)
}

const executeSpeedTest = async (speedTestContext: SpeedTestContext) =>
  new Promise((resolve) => {
    ndt7.test(
      config,
      {
        downloadMeasurement: function (measurement: any) {
          if (measurement.Source === 'client' && measurement.Data != null) {
            speedTestContext.setDownloadMbps(resolveMbps(measurement))
          }
        },
        downloadComplete: function (data: any) {
          speedTestContext.setDownloadMbps(
            data.LastClientMeasurement.MeanClientMbps
          )
        },
        uploadMeasurement: function (data) {
          if (data.Source === 'client') {
            const mbps = resolveMbps(data)
            speedTestContext.setUploadMbps(mbps)
          }
        },
        uploadComplete: function (data) {
          speedTestContext.setUploadMbps(
            data.LastClientMeasurement.MeanClientMbps
          )
          resolve(true)
        },
      },
      ndt7.discoverServerURLs(config)
    )
  })

export default { executeSpeedTest }
