import React, { ReactNode, useContext, useEffect, useState } from 'react'
import ipInfoService from '../services/ip-info-service'
import resourceService from '../services/resource-service'
import { AddressInfo, ApplicationContext, AppResources, IpInfo } from '../types'

const defaultAddressInfo: AddressInfo = {
  city: '',
  state: '',
  address1: '',
  address2: '',
  zip: '',
  country: '',
}

const AppContext = React.createContext<ApplicationContext>({
  address: defaultAddressInfo,
  setAddress: () => null,
  appResources: resourceService.defaultAppResources,
})

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [clientIpInfo, setClientIpInfo] = useState<IpInfo | undefined>()
  const [serverIpInfo, setServerIpInfo] = useState<IpInfo | undefined>()
  const [address, setAddress] = useState<AddressInfo>(defaultAddressInfo)
  const [appResources, setAppResources] = useState<AppResources>(
    resourceService.defaultAppResources
  )
  useEffect(() => {
    ipInfoService.getClientIpInfo().then(setClientIpInfo)
    ipInfoService.getIpInfo().then(setServerIpInfo)
    resourceService.getResources().then(setAppResources)
    return () => {}
  }, [])

  return (
    <AppContext.Provider
      value={{ clientIpInfo, serverIpInfo, address, setAddress, appResources }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)

export default AppContextProvider
