import React from 'react'
import { Language as Globe } from '@mui/icons-material'
import { useAppContext } from '../../context/app-context'
import InfoIcon, { iconStyle } from './'

const TestServerView = () => {
  const { serverIpInfo } = useAppContext()
  return (
    <InfoIcon
      id="server-ip"
      attributes={[serverIpInfo?.city, serverIpInfo?.ip]}
      icon={<Globe sx={iconStyle} />}
      alignItems="center"
    />
  )
}

export default TestServerView
