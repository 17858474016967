import { User } from '@auth0/auth0-react'
import {
  AddressInfo,
  getHydratedAddressInfo,
  hashAddress,
  IpInfo,
  SpeedTestContext,
  SpeedTestRecord,
  SpeedTestResult,
  UserInfo,
} from '../types'

type StorageKey = 'ip-info' | 'address-info' | 'speed-test-result'

export const saveData = (key: StorageKey, data: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    console.error(`Error saving ${key}`, e)
  }
}

export const getData = (key: StorageKey) => {
  const data = window.localStorage.getItem(key)
  if (data != null) {
    try {
      return JSON.parse(data) as unknown
    } catch (e) {
      console.error(`Error reading ${key}`, e)
      return
    }
  }
}

export const storeData = (
  ipInfo: IpInfo,
  addressInfo: AddressInfo,
  speedTestResult: SpeedTestResult
) => {
  saveData('ip-info', ipInfo)
  if (addressInfo.address1 != 'unknown') {
    saveData('address-info', addressInfo)
  }
  saveData('speed-test-result', speedTestResult)
}

const resolveIpInfo = (ipInfo: IpInfo | undefined) => {
  if (ipInfo != null) return ipInfo
  const data = getData('ip-info')
  if (data != null) return data as IpInfo
}

const resolveAddressInfo = (
  addressInfo: AddressInfo | undefined = undefined
) => {
  const hydratedAddressInfo =
    addressInfo != null ? getHydratedAddressInfo(addressInfo) : undefined
  if (hydratedAddressInfo != null) return hydratedAddressInfo
  const data = getData('address-info')
  if (data != null) return getHydratedAddressInfo(data as AddressInfo)

  return {
    city: 'unknown',
    state: 'unknown',
    address1: 'unknown',
    address2: 'unknown',
    zip: 'unknown',
    country: `unknown-${new Date().getTime()}`,
  }
}

const resolveTestResults = (speedTestResult: SpeedTestResult | undefined) => {
  if (
    speedTestResult != null &&
    speedTestResult.downloadSpeed != 0 &&
    speedTestResult.uploadSpeed != 0
  ) {
    return speedTestResult
  }

  const data = getData('speed-test-result')
  if (data != null) return data as SpeedTestResult
}

const save = async (
  ipInfo: IpInfo | undefined,
  user: User | undefined,
  speedTestContext: SpeedTestContext | undefined,
  addressInfo: AddressInfo | undefined
) => {
  const testResult: SpeedTestResult | undefined =
    speedTestContext == null
      ? undefined
      : {
          downloadSpeed:
            Math.round((speedTestContext.downloadMbps + Number.EPSILON) * 100) /
            100,
          uploadSpeed:
            Math.round((speedTestContext.uploadMbps + Number.EPSILON) * 100) /
            100,
        }

  const resolvedIpInfo = resolveIpInfo(ipInfo)
  const resolvedAddressInfo = resolveAddressInfo(addressInfo)
  const resolvedTestResult = resolveTestResults(testResult)

  if (
    resolvedIpInfo != null &&
    resolvedAddressInfo != null &&
    resolvedTestResult != null
  ) {
    const url = '/api/save'
    const email = user?.email ?? resolvedIpInfo.ip ?? 'unknown'
    const name = user?.name ?? resolvedIpInfo.ip ?? 'unknown'

    const users: UserInfo[] = [
      { email, name, ipInfo: resolvedIpInfo, ...resolvedTestResult },
    ]

    const payload: SpeedTestRecord = {
      users,
      addressInfo: resolvedAddressInfo,
      addressHash: hashAddress(resolvedAddressInfo),
    }

    storeData(resolvedIpInfo, resolvedAddressInfo, resolvedTestResult)

    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.status != 200) {
        console.error('An error occurred attempting to save telemetry.')
      }
    } catch (e) {
      console.error('An error occurred attempting to save telemetry.', e)
    }
  }
}

export default { save, storeData, resolveAddressInfo }
