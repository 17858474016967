import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useAppContext } from '../context/app-context'
import theme from '../theme'

const PoweredByGrid = () => {
  const appContext = useAppContext()
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ margin: '1rem 0', position: 'absolute', bottom: '0' }}
      borderTop={`1px solid ${theme.palette.primary.main}`}
    >
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          {appContext.appResources.drawer.poweredBy}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <a
          href="https://tribuquent.com"
          style={{ cursor: 'pointer', position: 'relative', right: '3px' }}
        >
          <img src="/tribuquent.png" width="160px" height="44px" />
        </a>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <a
          href="https://ipinfo.io"
          style={{ cursor: 'pointer', position: 'relative', right: '8px' }}
        >
          <img src="/ip-info.svg" width="160px" height="38px" />
        </a>
      </Grid>
    </Grid>
  )
}

export default PoweredByGrid
