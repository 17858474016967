import React from 'react'
import { Grid } from '@mui/material'
import DownloadGauge from './gauges/download-gauge'
import UploadGauge from './gauges/upload-gauge'
import PulseButton from './pulse-button'

const GaugeGrid = () => {
  return (
    <Grid container spacing={2} sx={{ margin: '2rem 0' }}>
      <Grid item xs={12} sm={12} lg={4} md={4} sx={{ textAlign: 'right' }}>
        <DownloadGauge />
      </Grid>
      <Grid item xs={12} sm={12} lg={4} md={4} sx={{ textAlign: 'right' }}>
        <PulseButton />
      </Grid>
      <Grid item xs={12} sm={12} lg={4} md={4} sx={{ textAlign: 'left' }}>
        <UploadGauge />
      </Grid>
    </Grid>
  )
}

export default GaugeGrid
