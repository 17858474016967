import React from 'react'
import { LocationOn } from '@mui/icons-material'
import { useAppContext } from '../../context/app-context'
import InfoIcon, { iconStyle } from './'

const IpInfoView = () => {
  const { clientIpInfo } = useAppContext()
  return (
    <InfoIcon
      id="ip"
      attributes={[clientIpInfo?.city, clientIpInfo?.ip]}
      icon={<LocationOn sx={iconStyle} />}
    />
  )
}

export default IpInfoView
