import * as React from 'react'
import { Typography, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import { useAppContext } from '../../context/app-context'
import theme from '../../theme'

const Footer = () => {
  const appContext = useAppContext()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '1rem',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          zIndex: 10,
        }}
        elevation={3}
      >
        <span>
          <a href="https://tribuquent.com" style={{ cursor: 'pointer' }}>
            <img src="/tribuquent.png" width="116.66px" height="32.6px" />
          </a>
        </span>
        <span>
          {!isSmall && (
            <Typography
              sx={{
                fontSize: '12px',
                color: 'gray',
                textAlign: 'center',
                lineHeight: '25px',
              }}
            >
              &copy; {appContext.appResources.footer.copyright}
            </Typography>
          )}
        </span>
        <span>
          <a href="https://ipinfo.io" style={{ cursor: 'pointer' }}>
            <img src="/ip-info.svg" width="116.66px" height="30px" />
          </a>
        </span>
      </Paper>
    </Box>
  )
}

export default Footer
