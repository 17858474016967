import { IpInfo } from '../types'

interface IpIfyResponse {
  ip: string
}

const getClientIpInfo = async () => {
  const url = 'https://api.ipify.org?format=json'
  const response = await fetch(url)
  const json = (await response.json()) as IpIfyResponse
  return await getIpInfo(json.ip)
}

const getIpInfo = async (ip: string = '') => {
  const storageKey = `ip-info-${ip}`

  if (window.localStorage.getItem(storageKey) != null) {
    return JSON.parse(
      window.localStorage.getItem(storageKey) as string
    ) as IpInfo
  } else {
    const ipInfoUrl = `/api/ip-info?ipAddr=${ip}`
    const data = await fetch(ipInfoUrl)
    const result = (await data.json()) as IpInfo
    window.localStorage.setItem(storageKey, JSON.stringify(result))
    return result
  }
}

export default { getIpInfo, getClientIpInfo }
