import React from 'react'
import { useAuth0, User } from '@auth0/auth0-react'
import { Person } from '@mui/icons-material'
import InfoIcon, { iconStyle } from './'

const resolveAttribute = (
  userInfo: User | undefined,
  key: 'email' | 'name'
) => {
  const attribute = userInfo == null ? '' : userInfo[key] ?? ''
  if (attribute.length > 20) {
    return attribute.substring(0, 15) + '...'
  } else {
    return attribute
  }
}

const UserInfoView = () => {
  const userInfo = useAuth0()
  const email = resolveAttribute(userInfo.user, 'email')
  const name = resolveAttribute(userInfo.user, 'name')
  return (
    <InfoIcon
      id="user"
      attributes={[name, email]}
      icon={<Person sx={iconStyle} />}
    />
  )
}

export default UserInfoView
